import React, { Component } from 'react'; 
import { NavLink } from 'react-router-dom';

class NavigationBarItemLink extends Component {
    render() {
        return (
            <NavLink exact activeClassName="activeLink" to = { this.props.link }> { this.props.label } </NavLink>
        );
    }
}

export default NavigationBarItemLink;