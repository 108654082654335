import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Card from '../card/card';
import Tilt from 'react-tilt';
import Masonry from 'react-masonry-component';
import { isMobile } from "react-device-detect";
import { Animated } from "react-animated-css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf, faFileVideo, faFileImage } from '@fortawesome/free-solid-svg-icons';

import '../responsive_grid/grid.scss';
import './experience.scss';

var data = [{
        title: "Bloomberg",
        subtitle: "Global Data Analyst",
        date: "London — Sept 19 to —",
        textBoxes: ["I have not started this position just yet."],
        color: 'orangered',
        tag: "workplace",
        content: null
    },
    {
        title: "Kinewell Energy",
        subtitle: "Software Engineer Intern",
        date: "Newcastle Upon Tyne — Feb 19 to Sept 19",
        textBoxes: ["Developed a C++ library that returns the shortest route between two points, with obstacle avoidance.", "Achieved optimal performance by reducing time compolexity, which is impertive in computational geometry."],
        color: 'orangered',
        tag: "workplace",
        content: null
    },
    {
        title: "Student Peer Mentor",
        subTitle: "Electrical and Electronic Engineering Peer Mentor",
        date: "Newcastle Upon Tyne — Sept 18 to Jun 19",
        textBoxes: ["Hosted drop in sessions and study groups to assist first year electrical and electronic engineering students."],
        tag: "volunteering",
        color: "#323031",
        content: null
    },
    {
        title: "Data Clustering for Machine Learning",
        subtitle: "Comparison and Application of Unsupervised Algorithms",
        date: "Newcastle University — Sept 18 to Jun 19" ,
        textBoxes: ["University dissertation exploring the K-Means, Expectation Maximisation and Variational Bayesian Inference clustering algorithms.", "The project involved mathematically deriving all algorithms followed by implementations in C++ and Python.", " Theory was applied to a FIFA dataset to extract underlying classes of football player position and performance."],
        color: '#3A506B',
        tag: "project",
        content: <div>
                    <div className="documentContainer">
                        <a href="https://s3.amazonaws.com/matthew-king.com-files/Data+Clustering+for+Machine+Learning+-+Matthew+King.pdf" target="_"> <FontAwesomeIcon icon={faFilePdf} size="1x" className="icon"/> </a>
                    </div>
                    <div className="documentContainer">
                        <a href="https://youtu.be/Vg_CfzoDW4k" target="_"> <FontAwesomeIcon icon={faFileVideo} size="1x" className="icon"/> </a>
                    </div>
                    <div className="documentContainer">
                        <a href="https://youtu.be/_4BmxXNNHvA" target="_"> <FontAwesomeIcon icon={faFileVideo} size="1x" className="icon"/> </a>
                    </div>
                </div>
    },
    {
        title: "Arm",
        subtitle: "Project Manager Intern",
        date: "Cambridge — Jun 18 to Sept 18",
        textBoxes: ["Researched the use of project data to perform data driven decision making, where data quality and availability were identified as major challenges.", "Hosted meetings with project stakeholders over the business for constructive feedback."],
        color: 'orangered',
        tag: "workplace",
        content: null
    },
    {
        title: "Mapping Vulnerable Places in The Developing World",
        subtitle: "Organised by Humanitarian OpenStreetMap Team",
        date: "Cambridge — Jul 18",
        textBoxes: ["Traced over satellite images to construct maps that can be used by non-profit-organisations and individuals to better respond to crises affecting vulnerable areas."],
        tag: "volunteering",
        color: "#323031",
        content: null
    },
    {
        title: "Society Vice President",
        subTitle: "Vice President of the Electrical and Electronic Engineering Society, ShockSoc.",
        date: "Newcastle Students Union — Sept 18 to Jun 19",
        textBoxes: ["Organised events and delegated tasks for the Electrical and Electronic Engineering Society."],
        tag: "volunteering",
        color: "#323031",
        content: null
    },
    {
        title: "Real-Time Trading Software",
        subtitle: "Bitcoin, Ethereum and Ripple Automated Trading Software",
        date: "Newcastle Upon Tyne — Sept 17 to Jun 18",
        textBoxes: ["Interfaced with Coinbase Exchange via REST APIs and connected to Websocket Feeds to collect real time order data.", "Used an SQL Database for order tracking and storing past price data.", "Implemented technical analysis for automatic trade signalling."],
        color: '#3A506B',
        tag: "project",
        content: null
    },
    {
        title: "Inductive Self Driving Buggy",
        subtitle: "Control System Design and Development",
        date: "Newcastle University — Jan 18 to Jun 18",
        textBoxes: ["Team university module project to develop a buggy that sensed a current carrying wire and automatically controlled its steering and speed motors to stay on the track.", "Developed and Implemented the Proportional, Integral and Differential control system."],
        color: '#3A506B',
        tag: "project",
        content: <div>
                    <div className="documentContainer">
                        <a href="https://s3.amazonaws.com/matthew-king.com-files/Thesis.pdf" target="_"> <FontAwesomeIcon icon={faFilePdf} size="1x" className="icon"/> </a>
                    </div>
                    <div className="documentContainer">
                        <a href="https://youtu.be/VSLCWsvDZDY" target="_"> <FontAwesomeIcon icon={faFileVideo} size="1x" className="icon"/> </a>
                    </div>
                </div>
    },
    {
        title: "Microprocessor System",
        subtitle: "Design and Construction of a Microprocessor System",
        date: "Newcaslte Upon Tyne — Sept 17 to Jan 18",
        textBoxes: ["Team university module project to design and build a microprocessor system that controls the colour of an LED.", "Using MultiSim and UltiBoard, a ColdFire+ Microprocessor, EEPROM Memory and multiple logic systems were integrated."],
        color: '#3A506B',
        tag: "project",
        content: <div>
                    <div className="documentContainer">
                        <a href="https://s3.amazonaws.com/matthew-king.com-files/Thesis.pdf" target="_"> <FontAwesomeIcon icon={faFileImage} size="1x" className="icon"/> </a>
                    </div>
                </div>
    },
    {
        title: "Handheld Snake Game Device",
        subtitle: "Integrating Systems",
        date: "Newcastle University — Sept 16 to Jun 17",
        textBoxes: ["Individual university project to build a game playable device comprising of an Arm MBED, a capacitive touchpad, a joystock and an 8x8 LED Matrix.", "Software was developed for snake tracking, visualising and scoring, using interupts for interfacing."],
        color: '#3A506B',
        tag: "project",
        content: <div>
                    <div className="documentContainer">
                        <a href="https://youtu.be/ektbsAEOV2w" target="_"> <FontAwesomeIcon icon={faFileVideo} size="1x" className="icon"/> </a>
                    </div>
                </div>
    },
    {
        title: "Maths — No Problem!",
        subtitle: "Content Creator and Technical Support",
        date: "Royal Tunbridge Wells — Sept 14 to Sept 16",
        textBoxes: ["Calculated and illustrated the answers to multiple primary school maths workbooks for website publishing.", "Provided a customer service help desk to solve technical issues. ", "Developed Excel solutions to improve sales and teacher training performance tracking."],
        color: 'orangered',
        tag: "workplace",
        content: null
    },
    {
        title: "Colour Recognition Sorting Device",
        subtitle: "Secondary School Project",
        date: "West Kent College — Sept 15 to Jun 16",
        textBoxes: ["Individual college project to design and construct a device that recognises presented colours, so that a ball can be moved to a colour matching compartment."],
        color: '#3A506B',
        tag: "project",
        content: <div>
                    <div className="documentContainer">
                        <a href="https://youtu.be/4klSBZjn60Q" target="_"> <FontAwesomeIcon icon={faFileVideo} size="1x" className="icon"/> </a>
                    </div>
                </div>
    }
];

class Experience extends Component {
    constructor(props){
        super(props);
        this.state = {
            lastKnownScreenWidth: window.innerWidth,
            filter: "all",
            activeButtonName: "all"
        }
        this.onResize = this.onResize.bind(this);
    }

    componentDidMount(){
        window.addEventListener('resize', this.onResize);
    }

    componentWillUnmount(){
        window.removeEventListener('resize', this.onResize);
    }

    onResize = (event) => {
        this.setState({
            lastKnownScreenWidth: window.innerWidth
        });
    }

    getCards(filter){
        var items = data;

        if (filter !== "all"){
            items = items.filter(function(item) {
                if (item.tag !== filter){
                    return false;
                } else {
                    return true;
                }
            });
        }
        
        items = items.map((item, index) => {
            return (
                <span key={ index } style={{ padding: '10px' }}>
                    {(() => {
                        if (isMobile) {
                            return <Card title={item.title} subtitle={item.subtitle} date={ item.date } textBoxes={item.textBoxes} content={item.content} color={item.color}/>;
                        } else {
                            return (
                                <Tilt options={{ max: 15, scale: 1, reverse: true }}>
                                    <Card title={item.title} subtitle={item.subtitle} date={ item.date } textBoxes={item.textBoxes} content={item.content} color={item.color}/>
                                </Tilt>
                            );
                        }
                    })()}
                </span>
            );
        })
        return items;
    }

    render(){
        ReactGA.pageview(window.location.pathname + window.location.search);

        return(
            <div className="masonry_grid">

                <Animated animationIn="lightSpeedIn" isVisible= { true } >
                    <div className="filterBox">
                        <button className={this.state.activeButtonName === "all" ? "activeFilterButton filterButton" : "filterButton"} 
                                onClick={ () => { this.setState({ filter: "all", activeButtonName: "all" }) } } > 
                            All 
                        </button>
                        <button className={this.state.activeButtonName === "workplace" ? "activeFilterButton filterButton" : "filterButton"} 
                                onClick={ () => { this.setState({ filter: "workplace", activeButtonName: "workplace" }) } } > 
                            Workplace 
                        </button>
                        <button className={this.state.activeButtonName === "project" ? "activeFilterButton filterButton" : "filterButton"} 
                                onClick={ () => { this.setState({ filter: "project", activeButtonName: "project" }) } } > 
                            Projects 
                        </button>
                        <button className={this.state.activeButtonName === "volunteering" ? "activeFilterButton filterButton" : "filterButton"} 
                                onClick={ () => { this.setState({ filter: "volunteering", activeButtonName: "volunteering" }) } } > 
                            Volunteering 
                        </button>

                    </div>

                </Animated>

                <Masonry 
                        className={'my-gallery-class'} 
                        elementType={'div'} 
                        options={{}} 
                        disableImagesLoaded={false} 
                        updateOnEachImageLoad={false} 
                        imagesLoadedOptions={{}} >
                    { this.getCards(this.state.filter) }
                </Masonry>

            </div>
        );
    }
}

export default Experience;