import React, { Component } from 'react';
import { Animated } from "react-animated-css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

class EmailFields extends Component {
    constructor(props){
        super(props);
    
        this.continueButton = this.continueButton.bind(this);
    }

    componentDidMount(){
        this.input.focus();
    }

    validEntry(string){
        let pattern = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        if (!pattern.test(string)){
            return false;
        } else {
            return true;
        }
    }

    continueButton(){
        if (this.validEntry(this.input.value)) {
            this.props.saveValues({ reply_to: this.input.value });
            this.props.nextStep();
        } else {
            window.alert("You must enter a valid email");
        }
    }

    enterPressed(event){
        var code = event.keyCode || event.which;

        if(code === 13) { 
            this.continueButton();
        } 
    }

    render(){
        return (
            <div className="container">
                <Animated animationIn="lightSpeedIn" isVisible= { true }>
                    <input ref={c => (this.input = c)} type="text" placeholder="Enter Email" onKeyPress={this.enterPressed.bind(this)}></input>
                    <FontAwesomeIcon icon={ faArrowRight } size="3x" className="iconStyle" onClick={ this.continueButton }/>
                </Animated>
            </div>
        );
    }
}

export default EmailFields;