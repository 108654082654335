import React from 'react';
import ReactGA from 'react-ga';
import NavigationMenu from './components/navigation_bar/nav_bar';
import MobileNavigationMenu from './components/mobile_navigation_menu/nav_menu';
import { Switch, Route } from 'react-router-dom';
import HttpsRedirect from 'react-https-redirect';
import Home from './components/pages/home';
import Education from './components/pages/education';
import Experience from './components/pages/experience';
import Contact from './components/pages/contact';

import './App.css';
import './components/navigation_bar/nav_bar.scss';

const right_menu_items = [
    { label: 'education', link: '/education' },
    { label: 'experience', link: '/experience' },
    { label: 'contact', link: '/contact' }
];

const left_menu_items = [
    { label: 'matthew-king.com', link: '/', component: { Home } }
];

const Routes = () => (
    <Switch>
        <Route exact path='/' component={ Home } />
        <Route exact path='/education' component={ Education } />
        <Route exact path='/experience' component={ Experience } />
        <Route exact path='/contact' component={ Contact } />
    </Switch>
);

function App()  {
    ReactGA.initialize('UA-125422610-1');

    return (
        <HttpsRedirect>
            <MobileNavigationMenu items={ left_menu_items.concat(right_menu_items) }/>

            <div className="navigationMenu topRight">
                <NavigationMenu items = { right_menu_items } />
            </div>

            <div className="navigationMenu topLeft">
                <NavigationMenu items = { left_menu_items } />
            </div>

            <Routes/>
        </HttpsRedirect>
    );
}

export default App;
