import React, { Component } from 'react';
import ReactGA from 'react-ga';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import './home.scss';

class Home extends Component {
    render(){
        ReactGA.pageview(window.location.pathname + window.location.search);

        return (
            <nav className="position containerBox">
                <nav className="nameText">Matthew King </nav>
                <nav className="descriptionText">Global Data Analyst at Bloomberg. Electrical and Electronic Engineering graduate from Newcastle University. Energetic, delivery-focused and self-starting individual.</nav>

                <a href="https://www.linkedin.com/in/m-j-k/" target="_"><FontAwesomeIcon icon={ faLinkedin } size="3x" className="iconStyle"/></a>
                <a href="https://www.instagram.com/matthew._.king/" target="_"><FontAwesomeIcon icon={ faInstagram } size="3x" className="iconStyle"/></a>
                <a href="mailto:mail@matthew-king.com"><FontAwesomeIcon icon={ faEnvelope } size="3x" className="iconStyle"/></a>
                
            </nav>
            
        );
    }
}

export default Home;