import React, { Component } from 'react';
import ReactGA from 'react-ga';
import MultiStepContactForm from '../step_contact_form/multi_step_form';

class Contact extends Component {
    render(){
        ReactGA.pageview(window.location.pathname + window.location.search);
        
        return (
            <div><MultiStepContactForm /></div>
        );
    }
}

export default Contact;