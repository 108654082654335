import React, { Component } from 'react';
import NavMenuItem from './nav_menu_item';
import NavMenuBurgerButton from './nav_menu_burger';
import NavMenuDrawer from './nav_menu_drawer';

import './nav_menu.scss'

class MobileNavigationMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {
            menuOpen: false
        }
    }

    handleMenuClick() {
        this.setState({
            menuOpen: !this.state.menuOpen
        });
    }

    handleLinkClick() {
        this.setState({
            menuOpen: false
        });
    }

    render() {
        const styles = {
            page: {
                display:'flex',
                flexDirection:'column',
                alignItems: 'center'
            },
            topBar: {
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex: '99',
                opacity: 0.9,
                display:'flex',
                alignItems:'center',
                width: 'auto'
            }
        }

        var menu_items = this.props.items.map((item, index) => {
            return (
                <NavMenuItem key={ item.label } link={ item.link } delay={ `${index * 0.1}s` } onClick={() => {this.handleLinkClick();}}> { item.label } </NavMenuItem>
            );
        });

        return (
            <div className="bringToFront">

                <div style={ styles.page } className="hideMenu">

                    <div style={ styles.topBar }>

                        <NavMenuBurgerButton open={ this.state.menuOpen } onClick={ () => this.handleMenuClick() } color='white' />

                    </div>

                    <NavMenuDrawer open={ this.state.menuOpen }> { menu_items } </NavMenuDrawer>
                </div>

            </div>
        );
    }
}

export default MobileNavigationMenu;