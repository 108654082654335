import React, { Component } from 'react'; 
import NavigationMenuItem from './nav_bar_item';

class NavigationMenu extends Component {
    constructor(props){
        super(props);
        this.state = {
            lastKnownScrollPosition: window.scrollY,
            lastKnownScreenWidth: window.innerWidth
        }

        this.onScroll = this.onScroll.bind(this);
        this.onResize = this.onResize.bind(this);
    }

    componentDidMount(){
        window.addEventListener("scroll", this.onScroll);
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount(){
        window.removeEventListener("scroll", this.onScroll);
        window.removeEventListener("resize", this.onResize);
    }

    onScroll = (event) => {
        this.setState({
            lastKnownScrollPosition: window.scrollY
        });
    }

    onResize = (event) => {
        this.setState({
            lastKnownScreenWidth: window.innerWidth
        });
    }

    render() {
        var item_list = this.props.items.map((item, index) => {
            return (
                <NavigationMenuItem key={ item.label } label={ item.label } link={ item.link } />
            );
        });

        return (
            <div style={ {
                opacity: this.state.lastKnownScrollPosition > 50 ? 0 : 1,
                transition: 'all 0.2s ease',
                visibility: this.state.lastKnownScrollPosition > 50 || this.state.lastKnownScreenWidth < 890 ? 'hidden' : 'visible' } } >
                <ul> 
                    { item_list }
                </ul>
            </div>
        );
    }
}

export default NavigationMenu;