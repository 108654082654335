import React, { Component } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "./tabs.scss";

class TabsContainer extends Component {
    constructor(props){
        super(props);

        this.constructTabList = this.constructTabList.bind(this);
    }

    constructTabList(){
        return (
            <TabList>
            {
                this.props.tabList.map((item, index) => {
                    return <Tab key={ index }> { item } </Tab>
                })
            }
            </TabList>
        );
    }

    constructTabPanels(){
        return (
            this.props.tabPanels.map((item, index) => {
                return <TabPanel key={ index }> { item } </TabPanel>
            })
        );
    }

    render(){
        return(
            <Tabs>
                { this.constructTabList() }
                { this.constructTabPanels() }
            </Tabs>
        );
    }
}

export default TabsContainer;