import React, { Component } from 'react';
import NameFields from './name_fields';
import EmailFields from './email_fields';
import MessageFields from './message_fields';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckSquare } from '@fortawesome/free-solid-svg-icons'

import * as emailjs from 'emailjs-com';

var fieldValues = {
    from_name: null,
    reply_to: null,
    message_html: null,
    to_name: "Matthew King"
}

class MultiStepContactForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            step: 1
        }

        this.nextStep = this.nextStep.bind(this);
    }

    saveValues(fields){
        return function(){
            fieldValues = Object.assign({}, fieldValues, fields);
        }()
    }

    nextStep(){
        this.setState({
            step: this.state.step + 1
        });
    }

    render(){
        const styles = {
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: 'rgb(53, 205, 75)'
        }

        switch (this.state.step) {
            case 1:
                return <NameFields saveValues={ this.saveValues } nextStep={ this.nextStep } />
            case 2:
                return <EmailFields saveValues={ this.saveValues } nextStep={ this.nextStep } />
            case 3:
                return <MessageFields saveValues={ this.saveValues } nextStep={ this.nextStep } />
            case 4:
                emailjs.send('gmail', 'template_2QPCAfhD', fieldValues, 'user_sWlEYi4Jrd5sXM6U8bmbm');
                return <div style={ styles }><FontAwesomeIcon icon={ faCheckSquare } size="6x"/></div>
            default:
                return <NameFields saveValues={ this.saveValues } nextStep={ this.nextStep } />
        }
    }
}

export default MultiStepContactForm;