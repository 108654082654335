import React, { Component } from 'react';
import ReactGA from 'react-ga';
import Card from '../card/card';
import Tilt from 'react-tilt';
import TabsContainer from '../tabs/tabs.jsx';
import { isMobile } from "react-device-detect";
import Masonry from 'react-masonry-component';

import '../responsive_grid/grid.scss';
import '../scroll_bar/scroll_bar.scss';
import '../table/table.scss';
import './education.scss';

var UniTableOne = (
    <div style={{width: '100%', maxHeight: '150px', overflow: 'auto' }} id="scroll_bar">
        <table>
            <tbody>
                <tr><td>C Programming</td><td>91%</td></tr>
                <tr><td>Fields, Materials and Devices</td><td>77%</td></tr>
                <tr><td>Electronics I</td><td>75%</td></tr>
                <tr><td>Circuit Theory</td><td>84%</td></tr>
                <tr><td>Signals and Communications I</td><td>74%</td></tr>
                <tr><td>Communication Skills and Innovation</td><td>85%</td></tr>
                <tr><td>Engineering Mathematics I</td><td>71%</td></tr>
            </tbody>
        </table>
    </div>
)

var UniTableTwo = (
    <div style={{width: '100%', maxHeight: '150px', overflow: 'auto' }} id="scroll_bar">
        <table>
            <tbody>
                <tr><td>Computer Systems and Microprocessors</td><td>80%</td></tr>
                <tr><td>Project and Professional Issues</td><td>82%</td></tr>
                <tr><td>Signals and Communications II</td><td>81%</td></tr>
                <tr><td>Control and Electrical Machines</td><td>75%</td></tr>
                <tr><td>Digital Electronics</td><td>84%</td></tr>
                <tr><td>Semiconductor Devices and Analogue Electronics</td><td>81%</td></tr>
                <tr><td>Electromagnetic Fields & Waves</td><td>66%</td></tr>
            </tbody>
        </table>
    </div>
)

var UniTableThree = (
    <div style={{width: '100%', maxHeight: '150px', overflow: 'auto' }} id="scroll_bar">
        <table>
            <tbody>
                <tr><td>Accounting, Finance and Law for Engineer</td><td>86%</td></tr>
                <tr><td>Linear Controller Design and State Space</td><td>73%</td></tr>
                <tr><td>Electrical Machines</td><td>89%</td></tr>
                <tr><td>Digital Signal Processing</td><td>86%</td></tr>
                <tr><td>Industrial Automation and Robotics</td><td>85%</td></tr>
                <tr><td>Electric Drives</td><td>89%</td></tr>
                <tr><td>Power System Operation</td><td>79%</td></tr>
                <tr><td>Digital Control Systems</td><td>83%</td></tr>
                <tr><td>Individual Project and Dissertation</td><td>77%</td></tr>
            </tbody>
        </table>
    </div>
)

var CollegeTableOne = (
    <div style={{width: '100%', maxHeight: '259px', overflow: 'auto' }} id="scroll_bar">
        <table>
            <tbody>
                <tr><td>Controlling Systems using IT</td><td>Distinction</td></tr>
                <tr><td>Applied Electrical and Mechanical Science for Engineering</td><td>Distinction</td></tr>
                <tr><td>Mathematics for Engineering Technicians</td><td>Distinction</td></tr>
                <tr><td>Principles and Applications of Microcontrollers</td><td>Distinction</td></tr>
                <tr><td>Principles and Applications of Analogue Electronics</td><td>Distinction</td></tr>
                <tr><td>Principles and Applications of Electronic Devices and Circuits</td><td>Distinction</td></tr>
                <tr><td>Electrical and Electronic Principles</td><td>Distinction</td></tr>
                <tr><td>Engineering Design</td><td>Distinction</td></tr>
                <tr><td>Electronic Circuit Design and Manufacturing</td><td>Merit</td></tr>
            </tbody>
        </table>
    </div>
)

var CollegeTableTwo = (
    <div style={{width: '100%', maxHeight: '259px', overflow: 'auto' }} id="scroll_bar">
        <table>
            <tbody>
                <tr><td>Further Mathematics for Engineering Technicians</td><td>Distinction</td></tr>
                <tr><td>Microprocessor Systems and Applications</td><td>Distinction</td></tr>
                <tr><td>Electronic Measurement and Testing</td><td>Distinction</td></tr>
                <tr><td>Engineering Project</td><td>Distinction</td></tr>
                <tr><td>Communication for Engineering Technicians</td><td>Distinction</td></tr>
                <tr><td>Health and Safety in the Workplace</td><td>Merit</td></tr>
                <tr><td>Construction and Application of Digital Systems</td><td>Merit</td>
                </tr><tr><td>Electornic Fault Finding</td><td>Merit</td></tr>
            </tbody>
        </table>
    </div>
)
    
var data = [{
            title: "Newcastle University",
            subtitle: "BEng Electrical and Electronic Engineering",
            date: "Sept 16 to Jun 19",
            textBoxes: ["Achieved First Class Honours with a final average of 81%.", "Awarded the IET Prize for outstanding dissertation project and highest overall average.", "Awarded the Ivan E Sutherland Prize for overall excellence in the School of Engineering."],
            content: <TabsContainer tabList={["Year 1", "Year 2", "Year 3"]} tabPanels={[UniTableOne, UniTableTwo, UniTableThree]}/>,
            color: 'orangered'
        },
        {
            title: "West Kent College",
            subtitle: "BTEC Level 3 Electrical and Electronic Engineering",
            date: "Sept 14 to Jun 16",
            textBoxes: ["Achieved Distinction* Distinction* Distinction.", "Equivalent to A* A* A at A Level."],
            content: <TabsContainer tabList={["Year 1", "Year 2"]} tabPanels={[CollegeTableOne, CollegeTableTwo]}/>,
            color: 'orangered'
        }
];

class Education extends Component {
    render(){
        ReactGA.pageview(window.location.pathname + window.location.search);

        var cards_list = data.map((item, index) => {
            return (
                <span key={ index } style={ { padding: '10px' } }>
                    {(() => {
                        if (isMobile) {
                            return <Card title={item.title} subtitle={item.subtitle} date={ item.date } textBoxes={item.textBoxes} content={item.content} color={item.color}/>;
                        } else {
                            return (
                                <Tilt options={{ max: 15, scale: 1, reverse: true }}>
                                    <Card title={item.title} subtitle={item.subtitle} date={ item.date } textBoxes={item.textBoxes} content={item.content} color={item.color}/>
                                </Tilt>
                            );
                        }
                    })()}
                </span>
            );
        });
        
        return (
			<div className="masonry_grid_education">
				<Masonry 
					className={'my-gallery-class'} 
					elementType={'div'} 
					options={{}} 
					disableImagesLoaded={false} 
					updateOnEachImageLoad={false} 
					imagesLoadedOptions={{}} >
					{ cards_list }
				</Masonry>
			</div>
        );
    }
}

export default Education;