import React, { Component } from 'react';

class NavMenuDrawer extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: this.props.open? this.props.opem:false
        }
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.open !== prevState.open){
            return { open: nextProps.open };
        } else {
            return { open: prevState.open };
        }
    }

    render(){
        const styles = {
            container: {
                position: 'fixed',
                top: 0,
                left: 0,
                minHeight: this.state.open? '100%': 0,
                width: '100vw',
                marginBottom: "10px",
                display: 'flex',
                flexDirection: 'column',
                background: 'black',
                opacity: 1,
                color: '#fafafa',
                transition: 'height 0.3s ease',
                overflow: 'overlay'
            },
            menuList: {
                paddingTop: '10rem'
            }
        }

        return (
            <div style = { styles.container }>
                {(() => {
                    if ( this.state.open ){
                        return (
                            <div style = {styles.menuList }>
                                { this.props.children }
                            </div>
                        );
                    } else {
                        return null;
                    }
                })()}
            </div>
        );
    }
}

export default NavMenuDrawer;