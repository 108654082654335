import React, { Component } from 'react';

class NavMenuBurgerButton extends Component {
    constructor(props){
        super(props);
        this.state = {
            open: this.props.open? this.props.open:false,
            color: this.props.color? this.props.color:'black',
            lastKnownScrollPosition: window.scrollY,
            lastKnownScreenWidth: window.innerWidth
        }
        this.onScroll = this.onScroll.bind(this);
        this.onResize = this.onResize.bind(this);
    }
    
    componentDidMount(){
        window.addEventListener("scroll", this.onScroll);
        window.addEventListener("resize", this.onResize);
    }

    componentWillUnmount(){
        window.removeEventListener("scroll", this.onScroll);
        window.removeEventListener("resize", this.onResize);
    }

    onResize = (event) => {
        this.setState({
            lastKnownScreenWidth: window.innerWidth
        });
    }

    onScroll = (event) => {
        this.setState({
            lastKnownScrollPosition: window.scrollY
        });
    }

    static getDerivedStateFromProps(nextProps, prevState){
        if (nextProps.open !== prevState.open){
            return { open: nextProps.open };
        } else {
            return { open: prevState.open };
        }
    }

    handleClick(){
        this.setState({
            open: !this.state.open
        });
    }

    burgerVisible(){
        if ((this.state.lastKnownScrollPosition > 5 && !this.state.open && this.state.lastKnownScreenWidth < 888) || this.state.lastKnownScreenWidth > 890){
            return false;
        } else return true;
    }

    render(){
        const styles = {
            container: {
                height: '80px',
                width: '80px',
                display:'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                marginTop: "50px",
                marginLeft: "30px",
                opacity: this.state.lastKnownScrollPosition > 5 && !this.state.open && this.state.lastKnownScreenWidth < 888 ? 0 : 1,
                transition: 'all 0.2s ease',
                visibility: this.burgerVisible() ? 'visible' : 'hidden',
            },
            line: {
                height: '6px',
                width: '80px',
                background: 'orangered',
                transition: 'all 0.2s ease'
            },
            lineTop: {
                opacity: this.state.open ? 0 : 1,
                transform: this.state.open ? 'translateX(-16px)':'none',
                marginBottom: '20px'
            },
            lineMiddle: {
                width: this.state.open ? '60px' : '80px',
            },
            lineBottom: {
                opacity: this.state.open ? 0 : 1,
                transform: this.state.open ? 'translateX(-16px)':'none',
                marginTop: '20px'
            }      
        }

        return(
            <div style={ styles.container } onClick={ this.props.onClick ? this.props.onClick : ()=> {this.handleClick();}}>
              <div style={{ ...styles.line, ...styles.lineTop}}/>
              <div style={{ ...styles.line, ...styles.lineMiddle}}/>
              <div style={{ ...styles.line, ...styles.lineBottom}}/>
            </div>
          )
    }
}

export default NavMenuBurgerButton;