import React, { Component } from 'react';
import { Animated } from "react-animated-css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import autosize from "autosize";

import '../scroll_bar/scroll_bar.scss';

class MessageFields extends Component {
    constructor(props){
        super(props);

        this.message_html = ""
        this.validateBool = true;
        this.updateInput = this.updateInput.bind(this);
        this.continueButton = this.continueButton.bind(this);
    }

    componentDidMount(){
        this.textarea.focus();
        autosize(this.textarea);
    }

    updateInput = (event) => {
        this.message_html = event.target.value;
    }

    continueButton(){
        if (this.validateBool) {
            this.props.saveValues({ message_html: this.message_html });
            this.props.nextStep();
        } else {
            window.alert("You must enter a valid email");
        }
    }

    render(){
        return (
            <div className="container">
                <Animated animationIn="lightSpeedIn" isVisible= { true }>
                    <textarea id="scroll_bar" ref={c => (this.textarea = c)} rows={1} type="text" placeholder="Enter Message" onChange={ this.updateInput } ></textarea>
                    <FontAwesomeIcon icon={ faArrowRight } size="3x" className="iconStyle" onClick={ this.continueButton }/>
                </Animated>
            </div>
        );
    }
}

export default MessageFields;