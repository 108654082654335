import React, { Component } from 'react';
import { Animated } from "react-animated-css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import '../step_contact_form/name_fields.scss';

class NameFields extends Component {
    constructor(props){
        super(props);

        this.state = {
            from_name: "",
        }
        
        this.validateBool = false;
        this.updateInput = this.updateInput.bind(this);
        this.continueButton = this.continueButton.bind(this);
    }

    componentDidMount(){
        this.input.focus();
    }


    validEntry(string){
        if (string.length < 3) {
            return false;
        } else {
            return true;
        }
    }

    updateInput(event){
        this.setState({
            from_name: event.target.value
        });

        if (this.validEntry(event.target.value)){
            this.validateBool = true;
        } else {
            this.validateBool = false;
        }
    }

    continueButton(){
        if (this.validateBool) {
            this.props.saveValues(this.state);
            this.props.nextStep();
        } else {
            window.alert("You must enter a valid full name");
        }
    }

    enterPressed(event){
        var code = event.keyCode || event.which;

        if(code === 13) { 
            this.continueButton();
        } 
    }

    render(){
        return (
            <div className="container">
                <Animated animationIn="lightSpeedIn" isVisible= { true }>
                <input ref={c => (this.input = c)} type="text" placeholder="Enter Full Name" onChange={ this.updateInput } onKeyPress={this.enterPressed.bind(this)}></input>
                <FontAwesomeIcon icon={ faArrowRight } size="3x" className="iconStyle" onClick={ this.continueButton }/>
                </Animated>
            </div>
        );
    }
}

export default NameFields;