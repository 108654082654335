import React, { Component } from 'react'; 
import NavigationBarItemLink from './nav_bar_link';

class NavigationMenuItem extends Component {
    render () {
        return (
            <li> <NavigationBarItemLink label={ this.props.label } link = {this.props.link }/> </li>
        );
    }
}

export default NavigationMenuItem;