import React, { Component } from 'react';
import './card.scss'

class Card extends Component {
    setTitle(title){
        return(
            <div className="title"> { title } </div>
        );
    }
    
    setSubTitle(subtitle){
        return (
            <div className="subTitle"> { subtitle } </div>
        );
    }

    setDate(date){
        return (
            <div className="dateTitle"> { date } </div>
        )
    }
    
    render(){
        var textBoxes = this.props.textBoxes.map((item, index) => {
            return (
                <div key={ index } className="textBox"> { item } </div>
            );
        });

        return (
            <div className="card"> 
                <div className="cardHeader" style={{ backgroundColor: this.props.color } }>
                    { this.setTitle(this.props.title) }
                    { this.setSubTitle(this.props.subtitle) }
                    { this.setDate(this.props.date) }
                </div>

                { textBoxes }

                { this.props.content }
            </div>
        );
    }
}

export default Card;